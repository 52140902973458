<template>
  <section id="settings-integrations">
    <h2 class="pb-2">{{ $t('integrations') }}</h2>
    <div>
      <a v-if="selectedIntegration"
         @click="back"
         class="btn btn-themed btn-white btn-secondary mb-4">
        {{ $t('back') }}
      </a>
      <b-row v-if="!selectedIntegration">
        <b-col v-for="(int, intind) in availableIntegrations" :key="`av-${intind}`" md="6">
          <div class="integration-teaser position-relative" @click="selectIntegration(int.id)">
            <b-row class="align-items-center">
              <b-col cols="auto">
                <img :src="`/images/${int.icon}`"/>
              </b-col>
              <b-col>
                <h3>{{ int.title }}</h3>
                <div class="text-muted">{{ int.description }}</div>
              </b-col>
            </b-row>
            <span class="position-absolute on-bubble"
                  v-if="['jaas'].includes(int.id)"
                  :class="{ active: connected[int.id] }"
                  @click.stop="toggleActivate(int.id)">
              {{ connected[int.id] ? $t('on') : $t('off') }}
            </span>
          </div>
        </b-col>
      </b-row>
      <JaasIntegration v-if="selectedIntegration === 'jaas'"/>
      <OpenAIIntegration v-if="selectedIntegration === 'chat_bot'"/>
    </div>
  </section>
</template>

<script>
import EventBus from '@/services/event-bus'
import { mapGetters } from "vuex"
import JaasIntegration from "../../../parts/integrations/JaasIntegration.vue"
import OpenAIIntegration from "../../../parts/integrations/OpenAIIntegration.vue"
import { UsersService } from "../../../../services/api.service"

export default {
  components: {
    JaasIntegration,
    OpenAIIntegration
  },
  data() {
    return {
      availableIntegrations: [
        {
          id: 'jaas',
          title: 'Jitsi meet (8x8)',
          icon: 'jaas.png',
          description: 'Video calls (описание)'
        },
        {
          id: 'chat_bot',
          title: 'AI Bot',
          icon: 'openai.svg',
          description: 'Администрирование чат ботов'
        },
      ],
      selectedIntegration: null
    }
  },
  computed: {
    ...mapGetters(['schoolMetadata']),
    connected() {
      return {
        jaas: this.schoolMetadata.jaasOn
      }
    }
  },
  mounted() {
    EventBus.$emit('breadcrumbs', [
      {to: '/settings', title: this.$t('settings')},
      {to: '/settings/integrations', title: this.$t('integrations')},
    ])
    if(this.$route.params.integration) {
      this.selectedIntegration = this.$route.params.integration
    }
  },
  methods: {
    selectIntegration(id) {
      this.selectedIntegration = id
      this.$router.replace({
        params: { integration: id }
      })
    },
    back() {
      this.selectedIntegration = null
      this.$router.replace({
        params: { integration: null }
      })
    },
    async toggleActivate(id) {
      let res = await UsersService.saveMetaData({ [`${id}On`]: !this.schoolMetadata[`${id}On`] })
      if(res?.data?.data?.id) {
        this.$success(this.$t('data_saved'))
        this.$store.commit('setSchool', res.data.data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.integration-teaser {
  background: #FFFFFF;
  border: 1px solid #E6EAF2;
  border-radius: 16px;
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  h3 {
    font-size: 18px;
    margin-bottom: 4px;
  }
  img {
    width: 64px;
  }
  &:hover {
    background: #f6f6f6;
  }
}
</style>

