<template>
  <b-modal id="train-bot-modal"
           size="md"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('training') }}</h2>
      <a class="pointer position-relative modal-close"
         @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <label>{{ $t('bot_additional_instructions') }}</label>
    <AutosizeTextArea :minH="200"
                      :maxH="maxAutoH"
                      class="form-control-white"
                      :white="true"
                      v-model="form.instruction_text"/>
    <div class="small text-muted text-right">{{ form.instruction_text.length }} / 1500</div>
    <label class="mt-4">{{ $t('bot_additional_knowledge') }}</label>
    <AutosizeTextArea :minH="200"
                      :maxH="maxAutoH"
                      class="form-control-white mb-2"
                      :white="true"
                      v-model="form.response_text"/>
    <div class="small text-muted text-right">{{ form.response_text.length }} / 20000</div>
    <template v-slot:modal-footer>
      <b-button class="btn btn-themed"
                :disabled="loading"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
// import PlusSvg from '@/assets/svg/plus.svg'
import AutosizeTextArea from "../general/form/AutosizeTextArea.vue"
import {ChatService} from "../../../services/api.service"

export default {
  name: "TrainBotModal",
  props: {
    bot: {
      type: Object,
      required: true
    }
  },
  components: {
    AutosizeTextArea,
    CloseSvg,
    // PlusSvg
  },
  data() {
    return {
      text: '',
      loading: false,
      form: {
        instruction_text: '',
        response_text: '',
      }
    }
  },
  computed: {
    maxAutoH() {
      return (this.windowH - 320) / 2
    },
    windowH() {
      return window.innerHeight
    }
  },
  mounted() {
    this.loadInstructions()
  },
  methods: {
    async loadInstructions() {
      try {
        const res = await ChatService.getBotInstructions(this.bot.id)
        this.form.instruction_text = res?.data?.data?.instruction_text || ''
        this.form.response_text = res?.data?.data?.response_text || ''
      } catch (e) {
        this.instruction_text = ''
        this.response_text = ''
      }
    },
    async save() {
      try {
        this.loading = true
        let res = await ChatService.saveBotInstructions(this.bot.id,  { ...this.form })
        if(res?.data?.data?.id) {
          this.$toast(this, this.$t('data_saved'))
          this.$bvModal.hide('train-bot-modal')
        }
      } catch (e) {
        this.errResponse(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
