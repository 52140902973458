<template>
  <div>
    <h3>8x8</h3>
    <div class="mt-4">
      <label>{{ $t('appId') }}</label>
      <InputGroup :label="$t('appId')" v-model="form.jaasAppId"/>
      <label>{{ $t('keyId') }}</label>
      <InputGroup :label="$t('keyId')" v-model="form.jaasKeyId"/>
      <label>{{ $t('privateKey') }}</label>
      <AutosizeTextArea :minH="100"
                        :maxH="200"
                        v-model="form.jaasPrivateKey"/>
      <div class="form-group mt-4 text-right">
        <button @click="save" class="btn-themed px-4">{{ $t('save') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {UsersService} from "../../../services/api.service"
import InputGroup from "../../parts/general/form/InputGroup.vue"
import AutosizeTextArea from "../../parts/general/form/AutosizeTextArea.vue"

export default {
  name: "JaasIntegration",
  components: {AutosizeTextArea, InputGroup},
  data() {
    return {
      form: {
        jaasAppId: '',
        jaasKeyId: '',
        jaasPrivateKey: ''
      },
    }
  },
  computed: {
    ...mapGetters(['school']),
    metaData() {
      return this.school && this.school.meta_data ? this.school.meta_data : {}
    },
  },
  mounted() {
    if(this.school) {
      this.form.jaasAppId = this.metaData.jaasAppId
      this.form.jaasKeyId = this.metaData.jaasKeyId
      this.form.jaasPrivateKey = this.metaData.jaasPrivateKey
    }
  },
  methods: {
    async save() {
      try {
        let res = await UsersService.saveMetaData(this.form)
        if(res?.data?.data?.id) {
          this.$success(this.$t('data_saved'))
          this.$store.commit('setSchool', res.data.data)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

