<template>
  <div>
    <h3 class="mb-4">OpenAI</h3>
    <button class="btn-themed btn-white w-100"
            @click="addBot">
      <PlusSvg class="svg-black svg-icon-sm"/><span class="ml-3">{{ $t('add_bot') }}</span>
    </button>

    <b-modal id="bot-modal"
             size="md" modal-class="modal-right-pane">
      <template v-slot:modal-header="{ close }">
        <h2>{{ form.id ? $t('edit') : $t('add_bot') }}</h2>
        <a class="pointer position-relative modal-close" @click="close()">
          <CloseSvg/>
        </a>
      </template>
      <InputGroup v-model="form.title"
                  class="mt-4"
                  @enter="save"
                  :label="$t('title')"/>
      <CheckboxGroup v-model="form.student_page"
                     class="mt-5"
                     fid="student_page"
                     :label="$t('use_as_default')"/>
      <template v-slot:modal-footer>
        <b-button class="btn btn-themed" @click="save">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
    <div v-for="b in bots" :key="`bot-${b.id}`" class="bot-teaser">
      <b-row class="align-items-center">
        <b-col>
          {{ b.title }}
          <span class="badge badge-themed-success-light ml-2"
                v-if="b.student_page">
            {{ $t('use_as_default') }}
          </span>
        </b-col>
<!--        <b-col cols="auto">-->
<!--          <b-button variant="conf" @click="editBot(b)">-->
<!--            <EditSvg class="svg-icon-sm svg-darkgray"/>-->
<!--          </b-button>-->
<!--        </b-col>-->
        <b-col cols="auto">
          <b-dropdown right no-caret menu-class="noscrollbar" variant="link" class="d-inline-block pointer">
            <template v-slot:button-content>
              <MoreSvg class="d-inline-block"/>
            </template>
            <b-dropdown-item @click.stop="trainBot(b)">{{ $t('training') }}</b-dropdown-item>
            <b-dropdown-item @click.stop="testBot(b)">{{ $t('test') }}</b-dropdown-item>
            <b-dropdown-item @click.stop="editBot(b)">{{ $t('edit') }}</b-dropdown-item>
            <b-dropdown-item @click.stop="deleteBot(b)">{{ $t('delete') }}</b-dropdown-item>
          </b-dropdown>
<!--          <b-button variant="conf" @click="deleteBot(b)">-->
<!--            <DeleteSvg class="svg-icon-sm svg-darkgray"/>-->
<!--          </b-button>-->
        </b-col>
      </b-row>
    </div>
<!--    <div class="mt-4">-->
<!--      <label>{{ $t('appId') }}</label>-->
<!--      <InputGroup :label="$t('appId')" v-model="form.jaasAppId"/>-->
<!--      <label>{{ $t('keyId') }}</label>-->
<!--      <InputGroup :label="$t('keyId')" v-model="form.jaasKeyId"/>-->
<!--      <label>{{ $t('privateKey') }}</label>-->
<!--      <AutosizeTextArea :minH="100"-->
<!--                        :maxH="200"-->
<!--                        v-model="form.jaasPrivateKey"/>-->
<!--      <div class="form-group mt-4 text-right">-->
<!--        <button @click="save" class="btn-themed px-4">{{ $t('save') }}</button>-->
<!--      </div>-->
<!--    </div>-->
    <AIChat v-if="botForTesting"
            :bot="botForTesting"
            @close="botForTesting = null"/>
    <TrainBotModal v-if="botForTraining"
                   :bot="botForTraining"/>
<!--    <AIChat/>-->
  </div>
</template>

<script>
import { ChatService } from "../../../services/api.service"
import PlusSvg from '@/assets/svg/plus.svg'
import CloseSvg from '@/assets/svg/close.svg'
import MoreSvg from '@/assets/svg/more.svg'
import InputGroup from "../general/form/InputGroup.vue"
import CheckboxGroup from "../general/form/CheckboxGroup.vue"
import AIChat from "../specific/AIChat.vue"
import TrainBotModal from "./TrainBotModal.vue"

export default {
  name: "OpenAIIntegration",
  components: {
    TrainBotModal,
    AIChat,
    CheckboxGroup,
    InputGroup,
    PlusSvg,
    CloseSvg,
    MoreSvg
  },
  data() {
    return {
      bots: [],
      form: {},
      botForTesting: null,
      botForTraining: null
    }
  },
  async mounted() {
    await this.loadList()
  },
  methods: {
    async loadList() {
      const res = await ChatService.getChatBots()
      this.bots = res.data.data
      // this.botForTesting = this.bots[0] || null
    },
    addBot() {
      this.form = { title: '', id: null, student_page: false }
      this.$bvModal.show('bot-modal')
    },
    testBot(bot) {
      this.botForTesting = bot
      // console.log(bot)
    },
    async trainBot(bot) {
      this.botForTraining = bot
      await this.$nextTick()
      this.$bvModal.show('train-bot-modal')
    },
    editBot(bot) {
      this.form = { ...bot }
      this.$bvModal.show('bot-modal')
    },
    async deleteBot(bot) {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(async () => {
        await ChatService.deleteChatBot(bot.id)
        await this.loadList()
      })
    },
    async save() {
      try {
        let res = this.form.id ? await ChatService.updateChatBot(this.form.id, this.form) : await ChatService.createChatBot(this.form)
        if(res?.data?.data?.id) {
          this.$toast(this, this.$t('data_saved'))
          this.$bvModal.hide('bot-modal')
          await this.loadList()
        }
      } catch (e) {
        this.errResponse(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bot-teaser {
  padding: 24px 0;
  border-bottom: 1px solid #DADFEB;
}
</style>
